import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`12th April 2024`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">{`You can now create a Spring HTTP Interface backed by Armeria's `}<a parentName="li" {...{
          "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
        }}>{`type://WebClient`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5370"
        }}>{`#5370`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`WebClient webClient = ...;
ArmeriaHttpExchangeAdapter adapter = ArmeriaHttpExchangeAdapter.of(webClient);
MyService myService = HttpServiceProxyFactory.builderFor(adapter)
  .build()
  .createClient(MyService.class);
myService.hello();
`}</code></pre>
      </li>
      <li parentName="ul">{`You can use `}<a parentName="li" {...{
          "href": "type://KubernetesEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/kubernetes/endpoints/KubernetesEndpointGroup.html"
        }}>{`type://KubernetesEndpointGroup`}</a>{` to perform client-side load-balancing when sending requests to a K8s cluster. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4497"
        }}>{`#4497`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5001"
        }}>{`#5001`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`KubernetesClient kubernetesClient = new KubernetesClientBuilder().build();
EndpointGroup endpointGroup = KubernetesEndpointGroup
  .builder(kubernetesClient)
  .namespace("default")
  .serviceName("my-service")
  .build();
WebClient.of(HTTP, endpointGroup).get("/");
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now conditionally deserialize a JSON response depending on a predicate. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4382"
        }}>{`#4382`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5002"
        }}>{`#5002`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ResponseEntity<MyResponse> response =
  RestClient
    .of("http://my-endpoint.com")
    .get("/")
    .execute(ResponseAs.<MyResponse>json(SuccessMessage.class, !res -> res.status().isError())
                       .orElseJson(EmptyMessage.class, res -> res.status().isInformational())
                       .orElseJson(ErrorMessage.class)).join();
`}</code></pre>
      </li>
      <li parentName="ul">{`You can specify allowed CORS origins via regular expressions or predicates. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4982"
        }}>{`#4982`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`CorsService.builderForOriginRegex(".*example.*")
           .andForOrigin(origin -> origin.contains("armeria"))
`}</code></pre>
      </li>
      <li parentName="ul">{`You can specify a Micrometer `}<inlineCode parentName="li">{`DistributionStatisticConfig`}</inlineCode>{` for `}<a parentName="li" {...{
          "href": "type://MetricCollectingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/metric/MetricCollectingClient.html"
        }}>{`type://MetricCollectingClient`}</a>{` and
`}<a parentName="li" {...{
          "href": "type://MetricCollectingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/metric/MetricCollectingService.html"
        }}>{`type://MetricCollectingService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4781"
        }}>{`#4781`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4829"
        }}>{`#4829`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`DistributionStatisticConfig config = ...;
MetricCollectingService.builder(MeterIdPrefixFunction.ofDefault("test"))
                       .distributionStatisticConfig(config)
`}</code></pre>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HealthChecker#ofCpu(double,double):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthChecker.html#ofCpu(double,double)"
        }}>{`type://HealthChecker#ofCpu(double,double)`}</a>{` can be used to reflect CPU load in health check status. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4673"
        }}>{`#4673`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .service("/hc", HealthCheckService.of(HealthChecker.ofCpu(0.5, 0.5), ...));
`}</code></pre>
      </li>
      <li parentName="ul">{`You can flatten multiple `}<a parentName="li" {...{
          "href": "typeplural://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
        }}>{`typeplural://StreamMessage`}</a>{` using `}<a parentName="li" {...{
          "href": "type://StreamMessage#flatMap(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#flatMap(java.util.function.Function)"
        }}>{`type://StreamMessage#flatMap(Function)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3966"
        }}>{`#3966`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4227"
        }}>{`#4227`}</a></li>
      <li parentName="ul">{`Armeria's Spring integration now registers `}<a parentName="li" {...{
          "href": "type://ServerErrorHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerErrorHandler.html"
        }}>{`type://ServerErrorHandler`}</a>{` type beans automatically. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5443"
        }}>{`#5443`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5446"
        }}>{`#5446`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Bean
public ServerErrorHandler serverErrorHandler() {
  return myServerErrorHandler;
}
`}</code></pre>
      </li>
      <li parentName="ul">{`OAuth 2.0 modules have been refactored `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5476"
        }}>{`#5476`}</a>
        <ul parentName="li">
          <li parentName="ul">{`You can now easily implement your own `}<a parentName="li" {...{
              "href": "https://datatracker.ietf.org/doc/html/rfc6749#section-4.5"
            }}>{`OAuth 2.0 Extension Grants`}</a>{` by extending `}<a parentName="li" {...{
              "href": "type://AccessTokenRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/auth/oauth2/AccessTokenRequest.html"
            }}>{`type://AccessTokenRequest`}</a>{`.`}</li>
          <li parentName="ul">{`You can now use `}<a parentName="li" {...{
              "href": "https://datatracker.ietf.org/doc/html/rfc7523"
            }}>{`JSON Web Token`}</a>{` to obtain OAuth 2.0 access tokens.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Armeria JARs are now compatible with Java modules. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5543"
        }}>{`#5543`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ServerBuilder#virtualHost(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#virtualHost(java.lang.String)"
        }}>{`type://ServerBuilder#virtualHost(String)`}</a>{` returns a cached `}<a parentName="li" {...{
          "href": "type://VirtualHostBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/VirtualHostBuilder.html"
        }}>{`type://VirtualHostBuilder`}</a>{` if available. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5418"
        }}>{`#5418`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`assert Server.builder().virtualHost("a.com") ==
    Server.builder().virtualHost("a.com");
`}</code></pre>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://AuthService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/auth/AuthService.html"
        }}>{`type://AuthService`}</a>{` now records metrics for authentication results. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5074"
        }}>{`#5074`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5017"
        }}>{`#5017`}</a></li>
      <li parentName="ul">{`You can add custom `}<inlineCode parentName="li">{`details`}</inlineCode>{` when throwing an `}<inlineCode parentName="li">{`ArmeriaStatusException`}</inlineCode>{` in an `}<a parentName="li" {...{
          "href": "type://AbstractUnsafeUnaryGrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/protocol/AbstractUnsafeUnaryGrpcService.html"
        }}>{`type://AbstractUnsafeUnaryGrpcService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4306"
        }}>{`#4306`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4341"
        }}>{`#4341`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://XdsEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/xds/client/endpoint/XdsEndpointGroup.html"
        }}>{`type://XdsEndpointGroup`}</a>{` now supports `}<a parentName="li" {...{
          "href": "https://www.envoyproxy.io/docs/envoy/latest/intro/arch_overview/upstream/service_discovery#strict-dns"
        }}>{`Strict DNS`}</a>{`
and `}<a parentName="li" {...{
          "href": "https://www.envoyproxy.io/docs/envoy/latest/intro/arch_overview/upstream/health_checking"
        }}>{`health checking`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5503"
        }}>{`#5503`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://XdsBootstrap:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/xds/XdsBootstrap.html"
        }}>{`type://XdsBootstrap`}</a>{` now supports `}<inlineCode parentName="li">{`SelfConfigSource`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5459"
        }}>{`#5459`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://AccessLogWriter#log(RequestLog):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/AccessLogWriter.html#log(com.linecorp.armeria.common.logging.RequestLog)"
        }}>{`type://AccessLogWriter#log(RequestLog)`}</a>{` is now called with the `}<a parentName="li" {...{
          "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
        }}>{`type://ServiceRequestContext`}</a>{` set on the calling thread. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5500"
        }}>{`#5500`}</a></li>
      <li parentName="ul">{`You can now pass `}<a parentName="li" {...{
          "href": "type://RequestOnlyLog#authenticatedUser():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestOnlyLog.html#authenticatedUser()"
        }}>{`type://RequestOnlyLog#authenticatedUser()`}</a>{` to Logback's MDC easily using `}<inlineCode parentName="li">{`authenticated.user`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5499"
        }}>{`#5499`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Specifying `}<a parentName="li" {...{
          "href": "type://WebClientBuilder#followRedirects():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClientBuilder.html#followRedirects()"
        }}>{`type://WebClientBuilder#followRedirects()`}</a>{` now correctly throws an exception when a cyclic redirection occurs. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5491"
        }}>{`#5491`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5548"
        }}>{`#5548`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://Service#serviceAdded(ServiceConfig):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Service.html#serviceAdded(com.linecorp.armeria.server.ServiceConfig)"
        }}>{`type://Service#serviceAdded(ServiceConfig)`}</a>{` is now correctly invoked for decorators. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5544"
        }}>{`#5544`}</a></li>
      <li parentName="ul">{`Armeria client is now more tolerant to poorly encoded `}<inlineCode parentName="li">{`Location`}</inlineCode>{` header values when following redirects. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5477"
        }}>{`#5477`}</a></li>
      <li parentName="ul">{`You can now use Kotlin value classes in annotated services. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5294"
        }}>{`#5294`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5449"
        }}>{`#5449`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ExchangeType:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ExchangeType.html"
        }}>{`type://ExchangeType`}</a>{` is guessed correctly even if it is not set in `}<a parentName="li" {...{
          "href": "type://RequestOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/RequestOptions.html"
        }}>{`type://RequestOptions`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5517"
        }}>{`#5517`}</a></li>
      <li parentName="ul">{`Fixed a bug where Thrift client requests were leaking byte buffers when aborted early. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5535"
        }}>{`#5535`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://LoggingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/LoggingClient.html"
        }}>{`type://LoggingClient`}</a>{` and `}<a parentName="li" {...{
          "href": "type://LoggingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/LoggingService.html"
        }}>{`type://LoggingService`}</a>{` now correctly mask default sensitive headers. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5519"
        }}>{`#5519`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`type://GrpcService`}</a>{` now correctly handles `}<inlineCode parentName="li">{`InvalidProtocolBufferException`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5379"
        }}>{`#5379`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5554"
        }}>{`#5554`}</a></li>
      <li parentName="ul">{`Armeria server no longer incorrectly logs a duplicate route warning for routes with arbitrary parameter or header predicates. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5469"
        }}>{`#5469`}</a></li>
      <li parentName="ul">{`Annotated services annotated with `}<a parentName="li" {...{
          "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
        }}>{`type://@Param`}</a>{` and `}<a parentName="li" {...{
          "href": "type://@Default:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Default.html"
        }}>{`type://@Default`}</a>{` now returns sensible values when an empty value is received. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5515"
        }}>{`#5515`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5534"
        }}>{`#5534`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://WebSocketFrame#ofPong():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/websocket/WebSocketFrame.html#ofPong()"
        }}>{`type://WebSocketFrame#ofPong()`}</a>{` now correctly generates a `}<inlineCode parentName="li">{`PONG`}</inlineCode>{` Frame. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5533"
        }}>{`#5533`}</a></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`Complete`}</inlineCode>{` message in the GraphQL over WebSocket Protocol is now constructed securely. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5531"
        }}>{`#5531`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
        }}>{`type://ClientFactory`}</a>{` no longer throws an intermittent exception when shutting down. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5494"
        }}>{`#5494`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5524"
        }}>{`#5524`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` now also supports JSON streaming requests. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5218"
        }}>{`#5218`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5381"
        }}>{`#5381`}</a></li>
      <li parentName="ul">{`Fixed a possible memory leak when a custom gRPC marshaller is used. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5523"
        }}>{`#5523`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`armeria-brave`}</inlineCode>{` module has been fully removed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5453"
        }}>{`#5453`}</a>
        <ul parentName="li">
          <li parentName="ul">{`To upgrade to `}<inlineCode parentName="li">{`armeria-brave6`}</inlineCode>{`, see the `}<a parentName="li" {...{
              "href": "https://github.com/openzipkin/brave/releases/tag/6.0.0"
            }}>{`brave6 release notes`}</a>{`.`}</li>
          <li parentName="ul">{`If you wish to retain the previous module functionality, use `}<inlineCode parentName="li">{`armeria-brave5`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://LogWriterBuilder#responseCauseFilter(BiPredicate):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/LogWriterBuilder.html#responseCauseFilter(java.util.function.BiPredicate)"
        }}>{`type://LogWriterBuilder#responseCauseFilter(BiPredicate)`}</a>{` now accepts a `}<inlineCode parentName="li">{`BiPredicate`}</inlineCode>{`
with `}<a parentName="li" {...{
          "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
        }}>{`type://RequestContext`}</a>{` as its first parameter.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://XdsEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/xds/client/endpoint/XdsEndpointGroup.html"
        }}>{`type://XdsEndpointGroup`}</a>{` has been moved from `}<inlineCode parentName="li">{`com.linecorp.armeria.xds.client`}</inlineCode>{` to `}<inlineCode parentName="li">{`com.linecorp.armeria.xds.client.endpoint`}</inlineCode>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Server-side HTTP/1.1 request headers are converted to Armeria headers with zero-copy. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4853"
        }}>{`#4853`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4863"
        }}>{`#4863`}</a></li>
      <li parentName="ul">{`Armeria's Spring integration now applies beans in order of priority. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5527"
        }}>{`#5527`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5573"
        }}>{`#5573`}</a></li>
      <li parentName="ul">{`Armeria's native image build configuration has been updated `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5516"
        }}>{`#5516`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HttpHeaderNames#SEC_AD_AUCTION_FETCH:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpHeaderNames.html#SEC_AD_AUCTION_FETCH"
        }}>{`type://HttpHeaderNames#SEC_AD_AUCTION_FETCH`}</a>{` and `}<a parentName="li" {...{
          "href": "type://HttpHeaderNames#AD_AUCTION_SIGNALS:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpHeaderNames.html#AD_AUCTION_SIGNALS"
        }}>{`type://HttpHeaderNames#AD_AUCTION_SIGNALS`}</a>{` are added. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5492"
        }}>{`#5492`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5505"
        }}>{`#5505`}</a></li>
    </ul>
    <h2 {...{
      "id": "-documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-documentation",
        "aria-label": " documentation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📃 Documentation`}</h2>
    <ul>
      <li parentName="ul">{`Armeria's REST, gRPC and Thrift `}<a parentName="li" {...{
          "href": "https://armeria.dev/tutorials"
        }}>{`tutorials`}</a>{` has been revamped with a new format. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5445"
        }}>{`#5445`}</a></li>
      <li parentName="ul">{`A wrong import statement in the code examples has been fixed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5560"
        }}>{`#5560`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
        }}>{`type://CircuitBreaker`}</a>{` documentation has been updated to avoid usage of deprecated methods. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5472"
        }}>{`#5472`}</a></li>
      <li parentName="ul">{`Broken links in Kotlin and context propagation related documents have been updated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5437"
        }}>{`#5437`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://Flags#useOpenSsl():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Flags.html#useOpenSsl()"
        }}>{`type://Flags#useOpenSsl()`}</a>{` is deprecated in favor of `}<a parentName="li" {...{
          "href": "type://Flags#tlsEngineType():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Flags.html#tlsEngineType()"
        }}>{`type://Flags#tlsEngineType()`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5029"
        }}>{`#5029`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ServerBuilder#virtualHost(String,String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#virtualHost(java.lang.String,java.lang.String)"
        }}>{`type://ServerBuilder#virtualHost(String,String)`}</a>{`, `}<a parentName="li" {...{
          "href": "type://ServerBuilder#withVirtualHost(Consumer):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#withVirtualHost(java.util.function.Consumer)"
        }}>{`type://ServerBuilder#withVirtualHost(Consumer)`}</a>{`,
`}<a parentName="li" {...{
          "href": "type://VirtualHostBuilder#hostnamePattern(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/VirtualHostBuilder.html#hostnamePattern(java.lang.String)"
        }}>{`type://VirtualHostBuilder#hostnamePattern(String)`}</a>{` have been deprecated as they don't support `}<a parentName="li" {...{
          "href": "type://VirtualHostBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/VirtualHostBuilder.html"
        }}>{`type://VirtualHostBuilder`}</a>{` re-usability.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ClientAuthorization:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/auth/oauth2/ClientAuthorization.html"
        }}>{`type://ClientAuthorization`}</a>{` and OAuth2 grant related classes have been deprecated in favor of a newer API. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5476"
        }}>{`#5476`}</a></li>
    </ul>
    <h2 {...{
      "id": "-miscellaneous",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-miscellaneous",
        "aria-label": " miscellaneous permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙌 Miscellaneous`}</h2>
    <ul>
      <li parentName="ul">{`An Armeria project icon for IntelliJ has been added. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5467"
        }}>{`#5467`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <p>{`🚨 Please use at least `}<inlineCode parentName="p">{`Netty 4.1.108.Final`}</inlineCode>{` with this release.`}</p>
    <ul>
      <li parentName="ul">{`Brave 6.0.0 → 6.0.2`}</li>
      <li parentName="ul">{`Brotli4j 1.15.0 → 1.16.0`}</li>
      <li parentName="ul">{`java-control-plane 1.0.42 → 1.0.44`}</li>
      <li parentName="ul">{`Dropwizard 2.1.10 → 2.1.12`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.2.24 → 4.2.25`}</li>
      <li parentName="ul">{`Eureka 2.0.1 → 2.0.2`}</li>
      <li parentName="ul">{`fastUtil 8.5.12 → 8.5.13`}</li>
      <li parentName="ul">{`gRPC-Java 1.61.0 → 1.63.0`}</li>
      <li parentName="ul">{`Guava 33.0.0-jre → 33.1.0-jre`}</li>
      <li parentName="ul">{`Jackson 2.16.1 → 2.17.0`}</li>
      <li parentName="ul">{`JCTools 4.0.2 → 4.0.3`}</li>
      <li parentName="ul">{`Jetty`}
        <ul parentName="li">
          <li parentName="ul">{`9.4.52.v20230823 → 9.4.54.v20240208`}</li>
          <li parentName="ul">{`10.0.19 → 10.0.20`}</li>
          <li parentName="ul">{`11.0.19 → 11.0.20`}</li>
          <li parentName="ul">{`12.0.5 → 12.0.8`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Kotlin 1.9.22 → 1.9.23`}</li>
      <li parentName="ul">{`Kubernetes Client 6.10.0 → 6.11.0`}</li>
      <li parentName="ul">{`Micrometer 1.12.2 → 1.12.4`}</li>
      <li parentName="ul">{`Micrometer Tracing 1.2.2 → 1.2.4`}</li>
      <li parentName="ul">{`Netty 4.1.106.Final → 4.1.108.Final`}</li>
      <li parentName="ul">{`Netty io_uring 0.0.24.Final → 0.0.25.Final`}</li>
      <li parentName="ul">{`protobuf-jackson 2.2.0 → 2.5.0`}</li>
      <li parentName="ul">{`Reactor 3.6.2 → 3.6.4`}</li>
      <li parentName="ul">{`RESTEasy 5.0.7.Final → 5.0.9.Final`}</li>
      <li parentName="ul">{`Retrofit 2.9.0 → 2.11.0`}</li>
      <li parentName="ul">{`Sangria 4.0.2 → 4.1.0`}</li>
      <li parentName="ul">{`Scala`}
        <ul parentName="li">
          <li parentName="ul">{`2.12.18 → 2.12.19`}</li>
          <li parentName="ul">{`2.13.12 → 2.13.13`}</li>
          <li parentName="ul">{`3.3.0 → 3.4.1`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Spring 6.1.3 → 6.1.5`}</li>
      <li parentName="ul">{`Spring-Boot 3.2.2 → 3.2.4`}</li>
      <li parentName="ul">{`Tomcat`}
        <ul parentName="li">
          <li parentName="ul">{`8.5.98 → 8.5.100`}</li>
          <li parentName="ul">{`9.0.85 → 9.0.87`}</li>
          <li parentName="ul">{`10.1.18 → 10.1.20`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['Bue-von-hon', 'Dogacel', 'KonaEspresso94', 'Lincong', 'cirusthenter', 'codefromthecrypt', 'echo304', 'facewise', 'haneepark', 'heowc', 'ikhoon', 'jaeseung-bae', 'jrhee17', 'kezhenxu94', 'kojilin', 'kth496', 'mauhiz', 'minwoox', 'moromin', 'my4-dev', 'seonwoo960000', 'sh-cho', 'sleeplesslord', 'srasul', 'thachlp', 'trustin', 'xhanin', 'yashmurty', 'yejinio', 'yeojin-dev']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      